<template>
    <div class='page-com'>
       <div class='page-com-header'>
           <div class='page-com-line'>
               <div class='page-com-time'>事情发生时间</div>
               <div @click='showTime'>
                   <input class='page-com-input page-com-val' v-model="currentDate" readonly=“readonly”/> 
                    <i class='page-com-icon'></i>
               </div>
               
           </div>
           <div class='page-com-line'>
               <div class='page-com-time'>投诉对象</div>
               <input class='page-com-input' v-model='objVal' @change='changeInput($event)'/>
           </div>
       </div> 
        <div class="page-content">
            <div class="page-title">事件描述</div>
            <div class="c-area">
                 <van-field
                    v-model="desQuest"
                    rows="6"
                    autosize
                    type="textarea"
                    maxlength="300"
                    placeholder="请详细描述您的问题或建议，我们将及时跟进"
                    show-word-limit
                    @change='changeQuest($event)'
                    />
                
            </div>
            <!-- 添加照片 -->
            <div class='c-upload-text'>相关附件</div>
            <div class="c-upload">
                <div class="c-img-list">
                    <van-uploader v-model="fileList"  :max-count="3" upload-text="添加图片"  :preview-full-image="false" :max-size="1000*1024"  @oversize="onOversize" :before-read="beforeRead" :after-read="afterRead"/>
                </div>
            </div>
            <div class="c-desc">最多可上传3张图片，图片大小上限1M</div>
        </div>
        <div class='pag-footer'>
            <div class='pag-footer-btn' @click='submitBtn'>提交</div>
        </div>
        <Modal :show="showCard" @cancel="bankCancel" :showCancel="true" class='bank-card' :title="'选择年月日'" >
           <van-datetime-picker
                
                type="date"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="showCard=false"
                @confirm="confirmPicker"
                />
        </Modal>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import Modal from '@/components/Modal/Modal.vue'
import { onMounted, toRefs,reactive } from "vue"
import {Toast} from 'vant';
import APP from '../../utils/APP'
import API from '../../server/api'


export default ({
    name: 'Complaint',
    components: {
        Modal
    },
    setup() {
        const state=reactive({
            showCard:false,
            minDate: new Date(2021, 0, 1),
            maxDate: new Date(2050, 10, 1),
            currentDate:'',
            objVal:'',
            desQuest:'',
            fileList:[],
            fileName:'',
            fileType:'',
            newList:[]

        })
         const bankCancel=()=>{ 
            state.showCard=false
        }
        const cancel = (show) => {
            state.showCard=show
         }
        const confirm = (show) => {
            state.showCard=show  
        }
        const showTime=()=>{
            state.showCard=true
        }
        const confirmPicker=(val)=>{
            let year=val.getFullYear();
            let month=val.getMonth()+1;
            let day=val.getDate();
            if (month >= 1 && month<= 9) { month=`0${month}`}
            if (day >= 1 && day<= 9) { day=`0${day}`}
            state.currentDate=`${year}-${month}-${day}`
            state.showCard=false
        }
        const changeInput=(e)=>{
            state.objVal=e.target.value
        }
         const changeQuest=(e)=>{
            state.desQuest=e.target.value
        }
        const onOversize=(file)=>{
            Toast('文件大小不能超过 1M'); 
        }
        //上传图片
        const beforeRead=(file)=>{
            state.fileName=file.name
            state.fileType=file.type
            if (file.type!=='image/jpeg' && file.type!=='image/png' && file.type!=='image/jpg') {
                Toast('请上传正确格式图片');
                return false;
            }
            return true
        
        }
        const afterRead=async(file)=>{
            //上传图片到oss
            const result=await API.uploadImg({
                fileName:state.fileName,//图片名
                fileType:state.fileType
            })
            if(result){
                const imgUrl=result.host + result.dir;
                state.newList.push(imgUrl)  
            }
            console.log(state.newList);
        }
        //提交
        const submitBtn=async()=>{
            if(state.currentDate==''){
                Toast('请选择时间');
                return false;
            }
            if(state.objVal==''){
                Toast('请填写投诉对象');
                return false;
            }
            if(state.desQuest == '') {
                    Toast('请输入反馈内容');
                    return false;
            }
            if(state.newList.length==0) {
                    Toast('请上传图片');
                    return false;
            }
            // const parameter={
            //     time: state.currentDate,
            //     eventobj:state.objVal,
            //     content:state.desQuest
            // }
            const parameter=state.currentDate + state.objVal + state.desQuest
            const result= await API.feedback({
                feedContent:parameter,
                feedType:'COMPLAINTS',
                imgList: state.newList
            })
            //提交成功要做什么
             APP.GO_BACK(1)

        }
        return{
            ...toRefs(state),
             bankCancel,
            cancel,
            confirm,
            showTime,
            confirmPicker,
            changeInput,
            changeQuest,
            submitBtn,
            onOversize,
            beforeRead,
            afterRead
        }
    },
})
</script>